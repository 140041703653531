import React from "react";
import { Card, CardBody } from "reactstrap";
import { MY_INVOICES } from "Constant";

import Breadcrumbs from "Components/Breadcrumbs";

import TripsDataTable from "./TripsDataTable";

const InvoicePage = () => {
  const breadCrumbsProps = [MY_INVOICES];
  const list = "/operator/invoices";

  return (
    <Card>
      <CardBody>
        <div className="p-4">
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <TripsDataTable link={list} />
        </div>
      </CardBody>
    </Card>
  );
};

export default InvoicePage;
