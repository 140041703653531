import { Fragment } from "react";
import { Avatar } from "primereact/avatar";

import { Card, CardBody } from "reactstrap";

import Breadcrumbs from "Components/Breadcrumbs";

import { CREATE_DRIVER, EDIT_DRIVER, MY_USERS } from "Constant";

import { API, COLUMNS, CREATE_TAXI_DRIVER } from "./data";

// import SimpleDataTable from "Components/DataTables/SimpleDataTable";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItemWithZod";
import EditItem from "Components/EditItemWithZod";
import UploadAvatar from "Components/UploadDoc/UploadAvatar";

import SimpleDataTable from "Components/DataTables/SimpleDataTable";
import { Toolbar } from "primereact/toolbar";
import { CraeteBtn, RefreshBtn } from "Components/PrimeBtns/ToolBarBtns";

const UsersPage = () => {
  const breadCrumbsProps = [MY_USERS];

  const { list } = API;

  const users = require("./response.json");

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Toolbar
            style={{
              padding: "0.5srem 1rem",
              fontSize: "0.75rem",
              marginTop: "1%",
              marginBottom: "1%",
            }} // Further reduce padding and font size
            start={
              <div className="flex flex-wrap gap-2">
                <CraeteBtn modalType="createDriver" />
                <RefreshBtn />
              </div>
            }
          />

          <SimpleDataTable localData={users} link={list} newColumns={COLUMNS} />
        </CardBody>
      </Card>

      <ModalApp type="edit" title={EDIT_DRIVER}>
        <EditItem itemsUpdate={CREATE_TAXI_DRIVER} />
      </ModalApp>

      <ModalApp type="editUserImg" title={EDIT_DRIVER}>
        {/* <EditItem itemsUpdate={CREATE_TAXI_DRIVER} /> */}
        <UploadAvatar fileName="proof" />
      </ModalApp>

      <ModalApp type="createDriver" title={CREATE_DRIVER} size="xl">
        <AddItem addURL={API.create} items={CREATE_TAXI_DRIVER} />
        {/* <CreateDriver /> */}
      </ModalApp>
    </Fragment>
  );
};

export default UsersPage;
