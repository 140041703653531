import React from "react";
import { useDispatch } from "react-redux";

import { changeModalType, changeTitle } from "../../redux/modal";
import { changeCurrentRow } from "../../redux/currentRow";

const OpenModalBtn = ({ type, title, children, data }) => {
  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(changeModalType(type));
    dispatch(changeTitle(title));
    if (data) dispatch(changeCurrentRow(data));
  };

  return <div onClick={handleEdit}>{children}</div>;
};

export default OpenModalBtn;
