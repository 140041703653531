import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs as BreadcrumbsMantine,
  Anchor,
  Paper,
} from "@mantine/core";
import { Helmet } from "react-helmet";
import { BreadCrumb } from "primereact/breadcrumb";
import { HOME } from "Constant";

const Breadcrumbs = ({ breadCrumbsProps }) => {
  const { t } = useTranslation();
  const translatedTab = breadCrumbsProps.map((tab) => {
    if (typeof tab == "string") return { label: t(tab) };
    return {
      ...tab,
      label: t(tab.label),
    };
  });
  console.log(breadCrumbsProps[breadCrumbsProps.length - 1]);

  const title =
    breadCrumbsProps[breadCrumbsProps.length - 1] ||
    translatedTab[0]?.label ||
    translatedTab[0];

  const home = { label: t(HOME), icon: "pi pi-home", url: "/home" };
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <BreadCrumb
        model={translatedTab}
        home={home}
        style={{
          border: "none",
          background: "none",
          boxShadow: "none",
          marginLeft: "-3rem", // ou -2rem selon l'effet désiré
        }}
      />
    </Fragment>
  );
};

export default Breadcrumbs;
