import React from "react";

import { useDispatch } from "react-redux";

import { changeModalType, changeTitle } from "../../redux/modal";
import { changeCurrentRow } from "../../redux/currentRow";
import { ActionIcon } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

const EditBtn = ({ read, edit, extra }) => {
  const dispatch = useDispatch();

  const handleEdit = () => {
    const selectedRow = {
      getLink: read || edit,
      putLink: edit || read,
    };
    dispatch(changeModalType("edit"));
    dispatch(changeTitle(extra));
    dispatch(changeCurrentRow(selectedRow));
  };

  return (
    <ActionIcon size={30} variant="default" onClick={handleEdit}>
      <IconEdit />
    </ActionIcon>
  );
};

export default EditBtn;
