import TripsDataTable from "Pages/TripsPage/TripsDataTable";
import React from "react";

const TripsComp = ({ code }) => {
  const url = `/operator/trips`;
  const defColumnFilters = { taxi: code };
  return (
    <TripsDataTable
      link={url}
      defColumnFilters={defColumnFilters}
      taxiCode={code}
    />
  );
};

export default TripsComp;
