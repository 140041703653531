import { Fragment, useEffect, useState } from "react";
import { io } from "socket.io-client";
import MapApp from "./MapApp";
import Loading from "Components/Loading/Loading";
import { ProgressBar } from "primereact/progressbar";

// const SOCKET_URL = process.env.REACT_APP_URL_SOCKET;
const SOCKET_URL = "https://devbookingskt.taxiora.tn";

const nameSocket = "/operator";

const Main = ({ operatorCode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("Disconnected");
  const [taxiData, setTaxiData] = useState([]);
  const urlOperator = `${SOCKET_URL}${nameSocket}`;

  console.log("operatorCode :", operatorCode);

  let socket;

  useEffect(() => {
    socket = io(urlOperator);

    socket.on("connect", () => {
      console.log("Connected to Socket.IO");
      setStatus(`Connected (ID: ${socket.id})`);
      // Request taxi data
      socket.emit("getTaxis", { operator_code: "TAXIORA" });
    });

    socket.on("dataResponse", (data) => {
      setTaxiData(data);
      setIsLoading(false); // Set loading to false when data is received
    });

    socket.on("connect_error", (error) => {
      console.error("Connection error:", error);
      setStatus("Connection Error");
      setIsLoading(false); // Stop loading spinner on connection error
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
      setStatus("Socket Error");
      setIsLoading(false); // Stop loading spinner on socket error
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO");
      setStatus("Disconnected");
      setIsLoading(false); // Stop loading spinner when disconnected
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Fragment>
      {isLoading && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      )}
      <MapApp taxis={taxiData} />
      {isLoading && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      )}
    </Fragment>
  );
};

export default Main;
