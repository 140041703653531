import React, { useState } from "react";
import grayMarker from "../../assets/images/markers/gray_marker.png";
import greenMarker from "../../assets/images/markers/green_marker.png";
import redMarker from "../../assets/images/markers/red_marker.png";

import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import { IconCircleCheck, IconXboxX } from "@tabler/icons-react";
import { Button } from "primereact/button";
import Translate from "Components/Translate";
import { SHOW_PATH } from "Constant";
import axiosClient from "Services/axiosClient";
import { getData, getIconEmpty } from "helper/getData";

const mapPosition = [35.83422413, 10.59211575];

const passenger = {
  lat: "35.829296",
  lng: "10.590714",
};

const getPositions = (data) => {
  try {
    if (data) {
      const arrival_position = JSON.parse(data?.arrival_position);
      const departure_position = JSON.parse(data?.departure_position);

      const arrivalPos = [
        arrival_position?.lat ?? 0,
        arrival_position?.long ?? 0,
      ];
      const departurePos = [
        departure_position?.lat ?? 0,
        departure_position?.long ?? 0,
      ];
      return { arrivalPos, departurePos };
    } else {
      return { departurePos: [0, 0], arrivalPos: [0, 0] };
    }
  } catch {
    return { departurePos: [0, 0], arrivalPos: [0, 0] };
  }
};

const getIcon = (status) => {
  let iconUrl;
  switch (status) {
    case "off":
      iconUrl = grayMarker;
      break;
    case "free":
      iconUrl = greenMarker;
      break;
    case "busy":
      iconUrl = redMarker;
      break;
    default:
      iconUrl = grayMarker;
  }

  return L.icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  });
};

const defMapSize = {
  flex: "1 1 300px",
  minWidth: "300px",
  minHeight: "300px",
};
const defMapSize2 = {
  flex: "1 1 400px",
  minWidth: "400px",
  minHeight: "400px",
};

const TripDetails = ({ data }) => {
  const { departurePos, arrivalPos } = getPositions(data);

  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mapSize, setMapSize] = useState(defMapSize);

  const getTrajectory = () => {
    const url = `/operator/trip/${data.code}/trajectory`;
    setIsLoading(true);
    axiosClient
      .get(url)
      .then((res) => {
        setLocations(getData(res));
        setIsLoading(false);
        setIsError(false);
        setMapSize(defMapSize2);
      })
      .catch((err) => {
        console.log(err);
        setLocations([]);
        setIsLoading(false);
        setIsError(false);
      });
  };

  return (
    <div
      style={{
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: "1 1 300px",
            minWidth: "250px",
            marginBottom: "20px",
          }}
        >
          <h2>Informations sur le trajet</h2>
          <p style={{ display: "flex", alignItems: "center" }}>
            <strong style={{ marginRight: "5px" }}>complet :</strong>
            {data.completed ? (
              <IconCircleCheck color="green" />
            ) : (
              <IconXboxX color="red" />
            )}
          </p>
          <p className="custom-label-value">
            <strong>Adresse de départ&nbsp;:</strong>
            {getIconEmpty(data?.departure_address)}
          </p>
          <p className="custom-label-value">
            <strong>Adresse d’arrivée&nbsp;:</strong>
            {getIconEmpty(data?.arrival_address)}
          </p>
          <p className="custom-label-value">
            <strong>Nombre du booking&nbsp;:</strong>
            {getIconEmpty(data?.booking_number)}
          </p>
          <p className="custom-label-value">
            <strong>Nombre du devis&nbsp;:</strong>
            {getIconEmpty(data?.invoice_number)}
          </p>

          <p className="custom-label-value">
            <strong>Créé le&nbsp;:</strong>
            {getIconEmpty(data?.created_at)}
          </p>

          {data.code === "4485e8e3-a7bf-4537-a537-38394ab1a3c5" && (
            <>
              <h6> Détails course :</h6>
              <p className="custom-label-value">
                <strong>Ahmed Sabbeg&nbsp;:</strong>
                <IconXboxX color="red" />
              </p>
              <p className="custom-label-value">
                <strong>Haroun Bouriga&nbsp;:</strong>
                <IconCircleCheck color="green" />
              </p>
            </>
          )}
        </div>

        <div style={mapSize}>
          <MapContainer
            center={mapPosition}
            zoom={13}
            scrollWheelZoom={true}
            style={{
              width: "100%",
              height: "100%",
            }}
            fullscreenControl={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
                OpenStreetMap
              </a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {/* Marqueur de départ */}
            <Marker position={departurePos} icon={getIcon("free")}>
              <Popup>
                <h6>Départ</h6>
              </Popup>
            </Marker>

            {/* Trajectory path */}
            {locations.length > 0 && (
              <Polyline
                positions={locations.map((loc) => [loc.lat, loc.lng])}
                color="blue"
                weight={4}
                opacity={0.7}
              />
            )}
            <div
              style={{
                position: "absolute",
                bottom: "20px", // Distance from the bottom
                left: "20px", // Position the search bar on the left instead of the right
                // backgroundColor: "transparent",
                // padding: "10px 15px", // Adjusted padding for better spacing
                // borderRadius: "5px",
                boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                // backdropFilter: "blur(10px)",
                zIndex: 1000,
              }}
            >
              <Button
                loading={isLoading}
                // outlined
                label={<Translate>{SHOW_PATH}</Translate>}
                icon="pi pi-compass"
                severity="secondary"
                onClick={() => getTrajectory()}
              />
            </div>

            {data.code === "4485e8e3-a7bf-4537-a537-38394ab1a3c5" && (
              <Marker position={passenger} icon={getIcon("off")}>
                <Popup>
                  <h6>Passager : Achraf Berrir </h6>
                  <h6>Téléphone : 93413654</h6>
                </Popup>
              </Marker>
            )}

            {/* Marqueur d'arrivée */}
            <Marker position={arrivalPos} icon={getIcon("busy")}>
              <Popup>
                <h6>Arrivée</h6>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default TripDetails;
