import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const getclientCode = () => {
  try {
    const storedData = localStorage.getItem("userData");
    return JSON.parse(storedData)?.client?.code;
  } catch {
    return "";
  }
};

export const getCodeFromJWT = () => {
  try {
    // const clientCode = getclientCode(storedData);
    const token = Cookies.get("_auth");
    const decoded = jwtDecode(token);
    return decoded ? decoded?.code : getclientCode();
  } catch {
    return "";
  }
};
