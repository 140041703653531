import { Fragment } from "react";
import {
  Group,
  Avatar,
  Text,
  Accordion,
  Badge,
  Center,
  Indicator,
  Space,
} from "@mantine/core";

import { IconCrown } from "@tabler/icons-react";

import Translate from "Components/Translate";
import { ACTIVE, DISABLED } from "Constant";
import ListOfData from "./ListOfData";

function UsersComp({ data, updateBtn, client_code }) {
  const accordion = data.map((elem, i) => {
    const { image } = elem;
    const id = i.toString();

    const title = elem.usr_owner ? (
      <>
        <Text fw={700}>
          <Badge
            variant="gradient"
            gradient={{ from: "yellow", to: "orange", deg: 90 }}
            leftSection={<IconCrown size={14} />}
          >
            <Text size="lg">{elem.full_name}</Text>
          </Badge>
        </Text>
      </>
    ) : (
      <Text fw={700}>
        <Badge variant="dot" color="gray">
          <Text size="lg">{elem.full_name}</Text>
        </Badge>
      </Text>
    );

    const currentState = elem.online ? (
      <Badge color="teal">en ligne</Badge>
    ) : (
      <Badge color="red">hors ligne</Badge>
    );

    const indicatorProps = elem.user_active
      ? { color: "green", label: <Translate>{ACTIVE}</Translate> }
      : { color: "red", label: <Translate>{DISABLED}</Translate> };

    return (
      <Accordion.Item value={id} key={id}>
        <Center>
          <Accordion.Control>
            <Group wrap="nowrap">
              <Indicator
                inline
                label={indicatorProps.label}
                color={indicatorProps.color}
                // size={16}
              >
                {image && <Avatar src={image} radius="xl" size="lg" />}
                {!image && (
                  <Avatar radius="xl" size="lg" color="orange"></Avatar>
                )}
              </Indicator>

              <Fragment>
                {title}
                <Space h="xs" />
                {currentState}
              </Fragment>
            </Group>
          </Accordion.Control>
          {/* <DriverSettingsBtn data={elem} /> */}

          <Space w="xl" />
        </Center>

        <Accordion.Panel>
          <ListOfData driverDetails={elem} client_code={client_code} />
        </Accordion.Panel>
      </Accordion.Item>
    );
  });

  return (
    <Fragment>
      {/* <HeaderTab
        tab={[
          <CreateDriver />,
          <div style={{ display: "flex" }}>
            <Space w="md" />
            {updateBtn}
          </div>,
        ]}
      /> */}

      <Space h="md" />
      <Accordion chevronPosition="left" variant="contained">
        {accordion}
      </Accordion>
    </Fragment>
  );
}

export default UsersComp;
