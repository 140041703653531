import moment from "moment";
import {
  formatTripDistance,
  formatTripDuration,
  getTripPrice,
} from "helper/trips";

import {
  CREATED_AT,
  DATE_BEGIN,
  DATE_END,
  DRIVER,
  DURATION,
  PRICE,
  DISTANCE,
  TAXI,
  MY_TRIPS,
} from "Constant";
export const COLUMNS = [
  {
    name: "N°",
    selector: "invoice_number",
    // driver_image
  },
  {
    name: DRIVER,
    body: (data) => data.driver,
    // driver_image
  },
  {
    name: TAXI,
    body: (data) => `${data?.licence} / ${data.car_number}`,
    // driver_image
  },
  {
    name: DATE_BEGIN,
    selector: "date_begin",
  },
  {
    name: DATE_END,
    selector: "end_at",
  },
  {
    name: PRICE,
    body: (data) => getTripPrice(data.total_price),
  },
];
