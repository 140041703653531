import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import useFetchData from "hooks/useFetchDataWithDep";
import React, { Fragment, useState } from "react";
import DateViewApp from "./DateViewApp";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import { DataViewLayoutOptions } from "primereact/dataview";
import { Toolbar } from "primereact/toolbar";
import { Paginator } from "primereact/paginator";
import Translate from "Components/Translate";
import { TOTAL } from "Constant";

const SearchBar = ({ search, setSearch, total }) => {
  return (
    <div className="p-inputgroup">
      <span className="p-inputgroup-addon">
        <i className="pi pi-search" style={{ marginRight: "8px" }} />
        <Translate>{TOTAL}</Translate>
        <span style={{ marginLeft: "8px" }}>{total}</span>
      </span>
      <InputText
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
        style={{ width: "100%" }}
      />
    </div>
  );
};

const Header = ({ LeftToolBar, layout, setLayout }) => (
  <Toolbar
    className="mb-4 bg-white text-black border border-gray-300"
    start={<LeftToolBar />}
    end={
      <DataViewLayoutOptions
        layout={layout}
        onChange={(e) => setLayout(e.value)}
      />
    }
  />
);

const DateViewLocal = ({ LeftToolBar, link, gridItem, listItem, data }) => {
  const [search, setSearch] = useState("");
  const [layout, setLayout] = useState("grid");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const listOfData = filterBySearch(data, search);

  console.log("listOfData :", listOfData);

  return (
    <Fragment>
      <Header LeftToolBar={LeftToolBar} layout={layout} setLayout={setLayout} />
      <SearchBar
        search={search}
        setSearch={setSearch}
        total={listOfData?.length}
      />

      <DateViewApp
        layout={layout}
        listOfData={listOfData}
        gridItem={gridItem}
        listItem={listItem}
      />
    </Fragment>
  );
};

export default DateViewLocal;

const filterBySearch = (array, searchString) => {
  if (!searchString) return array; // If no search string, return the full array

  const lowerCasedSearchString = searchString.toLowerCase();

  return array.filter((item) => {
    // Iterate through all keys of the item
    return Object.values(item).some((value) => {
      // Convert the value to string (in case it's a number or boolean)
      const valueString = String(value).toLowerCase();
      return valueString.includes(lowerCasedSearchString);
    });
  });
};
