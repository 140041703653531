import moment from "moment";
import {
  formatTripDistance,
  formatTripDuration,
  getTripPrice,
} from "helper/trips";

import {
  CREATED_AT,
  DATE_BEGIN,
  DATE_END,
  DRIVER,
  DURATION,
  PRICE,
  DISTANCE,
  TAXI,
  MY_TRIPS,
} from "Constant";
export const COLUMNS = [
  {
    name: DRIVER,
    body: (data) => data.driver,
    // driver_image
  },
  {
    name: TAXI,
    selector: "taxi",
    // body: (data) => data.taxi,
    // driver_image
  },
  {
    name: DATE_BEGIN,
    body: (data) => {
      return moment(data.date_begin).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    name: DATE_END,
    body: (data) => {
      return moment(data.end_at).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    name: PRICE,
    body: (data) => getTripPrice(data.total_price),
  },
  {
    name: DURATION,
    body: (data) => formatTripDuration(data.time_spend_millisecond),
  },
  {
    name: DISTANCE,
    body: (data) => formatTripDistance(data.traveled_distance_meters),
  },
];
