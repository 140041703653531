import { configureStore } from "@reduxjs/toolkit";

import toggleStyleReducer from "./toggleStyle";
import currentRowReducer from "./currentRow";
import searchLinkReducer from "./searchLink";
import modalReducer from "./modal";
import selectedRowsReducer from "./selectedRows";

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    currentRow: currentRowReducer,
    searchLink: searchLinkReducer,
    toggleStyle: toggleStyleReducer,
    selectedRows: selectedRowsReducer,
  },
});
