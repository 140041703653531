import { ActionIcon } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import SimpleForm from "Components/SimpleForm";

import patchItemForm from "Services/patchItemForm";
import { useParams } from "react-router-dom";
import { EDIT_SERVICE } from "Constant";
import Translate from "Components/Translate";

const formItem = [
  {
    title: "Date Begin",
    placeholder: "date_begin",
    name: "dateBegin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",
    placeholder: "date_end",
    name: "dateEnd",
    inputForm: "DateInput",
    required: false,
  },
  {
    name: "active",
    title: "Active",
    inputForm: "SelectInput",
    options: [
      {
        value: true,
        label: "active",
        name: "active",
      },
      {
        value: false,
        label: "not active",
        name: "active",
      },
    ],
    required: false,
  },
];

const ServiceSettings = ({ service }) => {
  const { code } = useParams();
  const {
    code_service,
    label_service,
    active_service,
    date_begin_service,
    date_end_service,
  } = service;

  const url = `/operator/taxi/${code}/service/${code_service}`;
  const [isOpen, setIsOpen] = useState(false);

  const oldData = {
    dateBegin: date_begin_service,
    dateEnd: date_end_service,
    active: active_service,
  };
  const onSubmit = (data) => {
    patchItemForm(url, data);
  };
  const toggleModel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <ActionIcon size={30} variant="default" onClick={toggleModel}>
        <IconEdit />
      </ActionIcon>

      <Modal
        isOpen={isOpen}
        toggle={toggleModel}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggleModel}>
          <Translate>{EDIT_SERVICE}</Translate> {label_service}
        </ModalHeader>
        <ModalBody>
          <SimpleForm items={formItem} oldData={oldData} onSubmit={onSubmit} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ServiceSettings;
