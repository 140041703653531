import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";

import { Controller } from "react-hook-form";
import useFetchData from "hooks/useFetchData";

import { getData } from "helper/getData";

import Translate from "Components/Translate";

const SelectApiInputGroup = ({
  register,
  inputName,
  errors,
  oldValue,
  control,
}) => {
  let options = [];
  const { name, title, optionsApi, getOptions, extrOptions, required, width } =
    inputName;
  const isReq = required === undefined ? true : false;
  const { data, isLoading, isError } = useFetchData(optionsApi);

  if (!isLoading && !isError) options = getOptions(getData(data));
  if (extrOptions) options.push(extrOptions);

  const groupedCities = options.map((elem) => ({
    label: elem.label,
    options: elem.options,
  }));

  const groupedItemTemplate = (option) => {
    return (
      <Divider align="left">
        <div className="inline-flex align-items-center">
          <i className="pi pi-car mr-2"></i>
          <b>{option.label}</b>
        </div>
      </Divider>
    );
  };

  return (
    <div className="col-3">
      <div className="flex flex-column ">
        <label>
          <Translate>{title}</Translate>
          {isReq && <span className="text-danger">*</span>}
        </label>

        <Controller
          name={name}
          control={control}
          defaultValue={oldValue}
          render={({ field }) => (
            <Dropdown
              loading={isLoading}
              filter
              onFilter={(e) => {}}
              id={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              invalid={errors[name]}
              optionLabel="label"
              options={groupedCities}
              optionGroupLabel="label"
              optionGroupChildren="options"
              optionGroupTemplate={groupedItemTemplate}
            />
          )}
        />

        {errors[name] && (
          <small className="text-danger">
            <Translate>{errors[name].message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};

export default SelectApiInputGroup;
