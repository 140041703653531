import { Fragment, useState } from "react";
import {
  LayersControl,
  LayerGroup,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";

import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen";

import { getIcon, statusCheckbox } from "./data";
import TaxiDetailsBox from "./TaxiDetailsBox";
import PopUpMarker from "./PopUpMarker";
import SearchBar from "./SearchBar";
// import { playBeep } from "./playBeep";

import { getInitLocation } from "helper/getLocation";

const MapApp = ({ taxis }) => {
  const [search, setSearch] = useState("");

  const mapPosition = getInitLocation();

  const getPosition = (taxi) => {
    try {
      // if (
      //   taxi.code === "7f4bf8e0-c2c0-43ce-aa66-34fff7c23362" &&
      //   taxi.status === "free"
      // )
      //   playBeep();
      return search
        ? searchByTaxi(taxi)
        : taxi.location.coordinates ?? taxi.location;
    } catch {
      return null;
    }
  };
  const searchByTaxi = (taxi) => {
    try {
      if (
        taxi.car.toLowerCase().includes(search.toLowerCase()) ||
        taxi.driver.toLowerCase().includes(search.toLowerCase())
      ) {
        return taxi.location.coordinates ?? taxi.location;
      }
    } catch {
      return null;
    }
  };

  return (
    <MapContainer
      center={mapPosition}
      zoom={13}
      scrollWheelZoom={true}
      style={{ height: "500px", width: "100%", minHeight: "500px !important" }}
      fullscreenControl={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LayersControl position="topleft">
        <LayersControl.Overlay name={statusCheckbox("off")} checked>
          <LayerGroup>
            {taxis
              .filter((taxi) => taxi.status === "off")
              .map((taxi) => (
                <Fragment key={taxi.code}>
                  {getPosition(taxi) && (
                    <Marker position={getPosition(taxi)} icon={getIcon("off")}>
                      <Popup>
                        <PopUpMarker data={taxi} />
                      </Popup>
                    </Marker>
                  )}
                </Fragment>
              ))}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name={statusCheckbox("free")} checked>
          <LayerGroup>
            {taxis
              .filter((taxi) => taxi.status === "free")
              .map((taxi) => (
                <Fragment key={taxi.code}>
                  {getPosition(taxi) && (
                    <Marker position={getPosition(taxi)} icon={getIcon("free")}>
                      <Popup>
                        <PopUpMarker data={taxi} />
                      </Popup>
                    </Marker>
                  )}
                </Fragment>
              ))}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name={statusCheckbox("busy")} checked>
          <LayerGroup>
            {taxis
              .filter((taxi) => taxi.status === "busy")
              .map((taxi) => (
                <Fragment key={taxi.code}>
                  {getPosition(taxi) && (
                    <Marker position={getPosition(taxi)} icon={getIcon("busy")}>
                      <Popup>
                        <PopUpMarker data={taxi} />
                      </Popup>
                    </Marker>
                  )}
                </Fragment>
              ))}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>

      <TaxiDetailsBox taxis={taxis} />
      <SearchBar setSearch={setSearch} />
    </MapContainer>
  );
};

export default MapApp;
