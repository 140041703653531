import axiosClient from "../../Services/axiosClient";
import errorMsg from "../../Services/errorMsg";

import { changedAlert } from "../Alerts/Alerts";

const editData = (data, url, changeLoadingState, success) => {
  axiosClient
    .put(url, data)
    .then(() => {
      changedAlert();
      success();
      changeLoadingState(false);
    })
    .catch((error) => {
      errorMsg(error);
      changeLoadingState(false);
    });
};
export default editData;
