import {
  TAXIS,
  EQUIPEMENT,
  USERS,
  HOME,
  BADGES,
  TRIPS,
  BOOKINGS,
  INVOICES,
  DRIVER_TAXIS,
} from "Constant";

import {
  IconArrowBadgeUp,
  IconCar,
  IconHome,
  IconIdBadge2,
  IconPigMoney,
  IconTimelineEventText,
  IconTipJarPound,
  IconTools,
  IconUsersGroup,
} from "@tabler/icons-react";

export const ABOUT = {
  menutitle: DRIVER_TAXIS,
  Items: [
    {
      path: "/home",
      icon: IconHome,
      type: "link",
      title: HOME,
    },
    {
      path: "/users",
      icon: IconUsersGroup,
      type: "link",
      title: USERS,
    },

    {
      path: "/taxis",
      icon: IconCar,
      type: "link",
      title: TAXIS,
    },
  ],
};

const DRIVERS_AXIS_MENU = {
  menutitle: TRIPS,
  Items: [
    {
      path: "/trips",
      icon: IconTipJarPound,
      type: "link",
      title: TRIPS,
    },
    {
      path: "/bookings",
      icon: IconTimelineEventText,
      type: "link",
      title: BOOKINGS,
    },
    {
      path: "/invoices",
      icon: IconPigMoney,
      type: "link",
      title: INVOICES,
    },
  ],
};

const EQUIPEMENTS_MENU = {
  menutitle: EQUIPEMENT,
  Items: [
    {
      path: "/badges",
      icon: IconIdBadge2,
      type: "link",
      title: BADGES,
    },
    {
      path: "/equipements",
      icon: IconTools,
      type: "link",
      title: EQUIPEMENT,
    },
  ],
};
export const MENU_ITEMS = [ABOUT, DRIVERS_AXIS_MENU];
