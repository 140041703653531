import { Fragment } from "react";
import { Avatar } from "primereact/avatar";

import { Card, CardBody } from "reactstrap";

import driver from "assets/images/driver.png";

import Breadcrumbs from "Components/Breadcrumbs";

import { CREATE_TAXI, EDIT_TAXI, MY_TAXIS } from "Constant";

import { API, COLUMNS, taxis } from "./data";

// import SimpleDataTable from "Components/DataTables/SimpleDataTable";
import ModalApp from "Components/ModalApp";
import EditItem from "Components/EditItemWithZod";

import AddItem from "Components/AddItemWithZod";

import { gridItem, listItem } from "./CardTaxi";
import DateViewLocal from "Components/DateViewLocal";
import LeftToolBar from "./LeftToolBar";

const UsersPage = () => {
  const breadCrumbsProps = [MY_TAXIS];

  const { list } = API;

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DateViewLocal
            data={taxis}
            LeftToolBar={LeftToolBar}
            link={list}
            gridItem={gridItem}
            listItem={listItem}
          />
        </CardBody>
      </Card>

      <ModalApp type="editUser" title={EDIT_TAXI}>
        <EditItem itemsUpdate={[]} />
      </ModalApp>

      <ModalApp type="editUserImg" title={EDIT_TAXI}>
        <EditItem itemsUpdate={[]} />
      </ModalApp>

      <ModalApp type="createDriver" title={CREATE_TAXI} size="xl">
        <AddItem addURL={API.create} items={[]} />
        {/* <CreateDriver /> */}
      </ModalApp>
    </Fragment>
  );
};

export default UsersPage;
