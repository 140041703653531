import React from "react";
import { Card, CardBody } from "reactstrap";
import {
  CREATED_AT,
  DATE_BEGIN,
  DATE_END,
  DRIVER,
  DURATION,
  PRICE,
  DISTANCE,
  TAXI,
  MY_TRIPS,
} from "Constant";

import Breadcrumbs from "Components/Breadcrumbs";

import TripsDataTable from "./TripsDataTable";

const TripsPage = () => {
  const breadCrumbsProps = [MY_TRIPS];
  const list = "/operator/trips";

  return (
    <Card>
      <CardBody>
        <div className="p-4">
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <TripsDataTable link={list} />
        </div>
      </CardBody>
    </Card>
  );
};

export default TripsPage;
