import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { DataTable as PrimeDataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import useFetchData from "hooks/useFetchDataWithDep";
import Translate from "Components/Translate";

import { EXPORT_EXCEL, REFRESH, THERE_IS_NO_DATA_FOUND, TOTAL } from "Constant";

import TripDetails from "./TripDetails";
import SeachAccordion from "./SeachAccordion";

import { useSelector } from "react-redux";
import { toggleRefresh } from "../../redux/searchLink";
import { store } from "../../redux/store";

import moment from "moment";
import { Paginator } from "primereact/paginator";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  formatTripDistance,
  formatTripDuration,
  getTripPrice,
} from "helper/trips";
import { Toolbar } from "primereact/toolbar";
import ExportExcel from "./ExportExcel";
import { COLUMNS } from "./data";

const DEFAULT_SORTING = [{ id: "created_at", desc: true }];

const getDate = (date) => {
  try {
    const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    return formattedDate === "Invalid date" ? date : formattedDate;
  } catch {
    return date;
  }
};

export default function TripsDataTable({
  link,
  defColumnFilters = {},
  taxiCode = "",
}) {
  const { refresh } = useSelector((state) => state.searchLink);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState({
    ...defColumnFilters,
    date_begin: new Date("2024-09-09 05:00:00"),
    date_end: new Date("2024-12-09 23:59:59"),
  });
  const [sorting, setSorting] = useState(DEFAULT_SORTING);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(globalFilter);

  const [expandedRows, setExpandedRows] = useState([]);
  const { t } = useTranslation();

  const url = useMemo(() => {
    const params = new URLSearchParams();
    params.append("size", pagination.pageSize);
    params.append("index", pagination.pageIndex + 1);

    Object.entries(columnFilters).forEach(([key, value]) => {
      if (key === "date_begin" || key === "date_end")
        params.append(key, getDate(value));
      else params.append(key, value);
    });

    if (debouncedSearch) {
      params.append("search", debouncedSearch);
    }

    sorting.forEach((filter) => {
      const val = filter.desc ? "DESC" : "ASC";
      params.append("sort_order", val);
      params.append("sort_column", filter.id);
    });

    let queryString = params.toString();
    queryString = queryString
      .replaceAll(/%20/g, " ")
      .replaceAll(/%3A/g, ":")
      .replaceAll("+", " ")
      .replaceAll("undefined", "");

    return `${link}?${queryString}`;
  }, [
    pagination.pageSize,
    pagination.pageIndex,
    debouncedSearch,
    columnFilters,
    sorting,
    link,
  ]);

  const { data, isLoading, isError } = useFetchData(url, refresh, url);
  const { total: paginationTotalRows, rows: listOfData } = getRows(data);

  const RefreshBar = () => (
    <div className="flex flex-wrap gap-2">
      <Button
        onClick={() => store.dispatch(toggleRefresh())}
        // icon="pi pi-refresh"
        // severity="secondary"
        // outlined
        label={<Translate>{REFRESH}</Translate>}
        icon="pi pi-sync"
        severity="secondary"
        // raised
      />
      <ExportExcel total={paginationTotalRows} taxiCode={taxiCode} />
    </div>
  );

  const onRowToggle = (e) => {
    setExpandedRows(e.data);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(globalFilter);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [globalFilter]);

  const onPageChange = (e) => {
    setPagination({
      pageIndex: e.page,
      pageSize: e.rows,
    });
  };

  // Calculez la somme totale des total_price
  const calculateTotals = (list) => {
    return (
      list?.reduce(
        (acc, row) => {
          acc.totalPrice += parseFloat(row.total_price) || 0;
          acc.totalTime += parseFloat(row.time_spend_millisecond) || 0;
          acc.totalTraveled += parseFloat(row.traveled_distance_meters) || 0;

          return acc;
        },
        { totalPrice: 0, totalTime: 0, totalTraveled: 0 }
      ) ?? { totalPrice: 0, totalTime: 0, totalTraveled: 0 }
    );
  };

  // Usage
  const { totalPrice, totalTime, totalTraveled } = calculateTotals(listOfData);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer={t(TOTAL)}
          colSpan={5}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={getTripPrice(totalPrice)} />
        <Column footer={formatTripDuration(totalTime)} />
        <Column footer={formatTripDistance(totalTraveled)} />
      </Row>
    </ColumnGroup>
  );

  return (
    <Fragment>
      <Toolbar
        style={{ padding: "0.5srem 1rem", fontSize: "0.75rem" }} // Further reduce padding and font size
        start={<RefreshBar />}
      />
      <div style={{ marginBottom: "0.5%", marginTop: "0.5%" }}>
        <SeachAccordion
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          total={paginationTotalRows}
        />
      </div>
      <PrimeDataTable
        footerColumnGroup={footerGroup}
        loading={isLoading}
        value={listOfData}
        showGridlines
        paginator={false}
        columnResizeMode="fit"
        stripedRows
        resizableColumns
        rowHover
        size="small"
        emptyMessage={t(THERE_IS_NO_DATA_FOUND)}
        rowExpansionTemplate={(data) => <TripDetails data={data} />}
        onRowToggle={onRowToggle}
        expandedRows={expandedRows}
        rowClassName="expanded-row"
      >
        <Column expander style={{ width: "5rem" }} />

        {COLUMNS.map((col, i) => {
          // Si votre colonne "Prix" est identifiée par col.selector === "total_price" :
          if (col.selector === "total_price") {
            // On ajoute un footer
            return (
              <Column
                key={i}
                field="total_price"
                header={t(col.name)}
                footer={`${totalPrice.toFixed(3)} TND`}
                body={col.body ? col.body : null}
              />
            );
          }
          return (
            <Column
              key={i}
              body={col.body ? col.body : null}
              field={col.selector ? col.selector : null}
              header={t(col.name)}
            />
          );
        })}
      </PrimeDataTable>
      <div className="card">
        <Paginator
          first={pagination.pageIndex * pagination.pageSize}
          rows={pagination.pageSize}
          totalRecords={paginationTotalRows}
          rowsPerPageOptions={rowsPerPageOptions(paginationTotalRows)}
          onPageChange={onPageChange}
          dropdownAppendTo={document.body}
          template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          // currentPageReportTemplate={`Showing {first} to {last} of {totalRecords} entries`}
          currentPageReportTemplate={` {first} - {last} | {totalRecords}`}
          // leftContent={<RefreshBar />}
          // rightContent={
          //   <span>
          //     <strong>
          //       <Translate>{TOTAL}</Translate> :
          //     </strong>
          //     {paginationTotalRows}
          //   </span>
          // }
        />
      </div>
    </Fragment>
  );
}

const getRows = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return { total: 1, rows: [] };
  }
};

const rowsPerPageOptions = (total) => {
  const options = [5, 10, 50, 100, 500, 1000];
  if (!options.includes(total)) {
    options.push(total);
  }
  return options.filter((option) => option <= total).sort((a, b) => a - b);
};
