export const formatTripDuration = (durationInMilliSecond) => {
  try {
    const durationInSeconds = durationInMilliSecond / 1000;
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = (durationInSeconds % 60).toFixed(2); // Round to 2 decimal places

    let result = "";
    if (hours > 0) result += `${hours}h `;
    if (minutes > 0 || hours > 0) result += `${minutes}m `;
    result += `${seconds}s`;

    return result.trim();
  } catch {
    return `${durationInMilliSecond} ms`;
  }
};

export const formatTripDistance = (tripDistance) => {
  if (!tripDistance) return tripDistance;
  try {
    const result = (tripDistance / 1000).toFixed(2) + " Km";
    return result;
  } catch {
    return tripDistance;
  }
};

export const getTripPrice = (price) => {
  try {
    // Convert price to a number if it's a string
    const numericPrice = parseFloat(price);

    // Check if the conversion is valid (NaN means invalid)
    if (isNaN(numericPrice)) {
      throw new Error("Invalid price");
    }

    const currency = JSON.parse(localStorage.getItem("currency"));

    return currency
      ? `${numericPrice.toFixed(2)} ${currency}`
      : numericPrice.toFixed(2);
  } catch (error) {
    console.error(error);
    return price;
  }
};
