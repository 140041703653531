import React, { Fragment, useState } from "react";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";

export default function DateViewApp({
  layout,
  listOfData = [],
  gridItem,
  listItem,
}) {
  const itemTemplate = (product, layout, index) => {
    if (!product) {
      return;
    }

    if (layout === "list") return listItem(product, index);
    else if (layout === "grid") return gridItem(product);
  };

  const listTemplate = (listOfData, layout) => {
    return (
      <div className="grid grid-nogutter">
        {listOfData.map((product, index) =>
          itemTemplate(product, layout, index)
        )}
      </div>
    );
  };

  return (
    <div className="card">
      <DataView
        value={listOfData}
        listTemplate={listTemplate}
        layout={layout}
        // header={header}
      />
    </div>
  );
}
