import React, { useState } from "react";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

import Translate from "Components/Translate";
import { EXPORT_EXCEL } from "Constant";

import axiosClient from "Services/axiosClient";
import { getData } from "helper/getData";

import {
  formatTripDistance,
  formatTripDuration,
  getTripPrice,
} from "helper/trips";

/**
 * Extrait et formate les données de trajet (trips).
 * @param {Array} trips Tableau de trajets (objects).
 * @returns {Array} Tableau d'objets formatés pour l'export.
 */
function extractTripData(trips) {
  try {
    return trips.map((trip) => ({
      taxi: trip.taxi,
      driver: trip.driver,
      date_begin: trip.date_begin,
      date_end: trip.end_at,
      total_price: getTripPrice(trip.total_price),
      time_spend_millisecond: formatTripDuration(trip.time_spend_millisecond),
      traveled_distance_meters: formatTripDistance(
        trip.traveled_distance_meters
      ),
    }));
  } catch {
    // En cas d’erreur inattendue
    return trips;
  }
}

/**
 * Filtre et formate les données principales avant export.
 * @param {Object} trips Objet contenant la clé 'rows' et les totaux.
 * @returns {Object} { trips, total, sum_total_price, total_time, total_distance }
 */
const filterData = (trips) => {
  try {
    const sum_total_price = getTripPrice(trips.sum_total_price);
    const total_time = formatTripDuration(trips.total_time);
    const total_distance = formatTripDistance(trips.total_distance);

    return {
      trips: extractTripData(trips?.rows),
      total: trips.total,
      sum_total_price,
      total_time,
      total_distance,
    };
  } catch {
    return { trips: trips };
  }
};

const ExportExcel = ({ total, taxiCode }) => {
  const url = taxiCode
    ? `/operator/trips?index=-1&taxi=${taxiCode}`
    : "/operator/trips?index=-1";
  const [isLoading, setIsLoading] = useState(false);

  const getExcelData = async () => {
    setIsLoading(true);
    const data = await axiosClient.get(url).then((res) => getData(res));
    setIsLoading(false);

    const { trips, total, sum_total_price, total_time, total_distance } =
      filterData(data);

    // Dynamically import 'xlsx' pour éviter de le charger partout
    import("xlsx").then((xlsx) => {
      // 1) Créer une feuille Excel vide
      const worksheet = xlsx.utils.aoa_to_sheet([]);

      // 2) Insérer le titre en A1
      xlsx.utils.sheet_add_aoa(worksheet, [["Liste des courses"]], {
        origin: "A1",
      });

      // 3) Insérer la date courante en A2
      const today = new Date();
      xlsx.utils.sheet_add_aoa(worksheet, [["Date", today]], { origin: "A2" });

      // 4) La ligne 3 reste libre, on commence à écrire les totaux ligne 4
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Nombre de courses",
            "Prix total",
            "Durée totale",
            "Distance totale",
          ],
        ],
        { origin: "A4" }
      );

      // 5) Les valeurs des totaux en ligne 5
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [[total, sum_total_price, total_time, total_distance]],
        { origin: "A5" }
      );

      // 6) On saute la ligne 6 vide, entête des trajets en ligne 7
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [
          [
            { v: "Taxi", s: { font: { bold: true } } }, // Make "Taxi" bold
            { v: "Chauffeur", s: { font: { bold: true } } }, // Make "Chauffeur" bold
            { v: "Date de début", s: { font: { bold: true } } }, // Make "Date de début" bold
            { v: "Date de fin", s: { font: { bold: true } } }, // Make "Date de fin" bold
            { v: "Prix", s: { font: { bold: true } } }, // Make "Prix" bold
            { v: "Durée", s: { font: { bold: true } } }, // Make "Durée" bold
            { v: "Distance", s: { font: { bold: true } } }, // Make "Distance" bold
          ],
        ],
        { origin: "A7" }
      );

      // 7) Insérer les données de vos trajets à partir de la ligne 8
      xlsx.utils.sheet_add_json(worksheet, trips, {
        skipHeader: true,
        origin: "A8",
        header: [
          "taxi",
          "driver",
          "date_begin",
          "date_end",
          "total_price",
          "time_spend_millisecond",
          "traveled_distance_meters",
        ],
      });

      // 8) Ajuster la largeur des colonnes
      worksheet["!cols"] = [
        { wch: 35 }, // A
        { wch: 20 }, // B
        { wch: 20 }, // C
        { wch: 20 }, // D
        { wch: 15 }, // E
        { wch: 20 }, // F
        { wch: 25 }, // G
      ];

      // 9) Fusion (merge) de la cellule A1 à G1 pour le titre
      worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }, // Merge pour le grand titre
      ];

      // Application des styles (si la version de xlsx le permet) :

      // Style du titre (A1)
      if (!worksheet["A1"].s) worksheet["A1"].s = {};
      worksheet["A1"].s = {
        font: {
          name: "Arial",
          sz: 16, // Taille de police
          bold: true,
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
      };

      // 1) Column widths
      worksheet["!cols"] = [
        { wch: 35 }, // A
        { wch: 20 }, // B
        { wch: 20 }, // C
        { wch: 20 }, // D
        { wch: 15 }, // E
        { wch: 20 }, // F
        { wch: 25 }, // G
      ];

      // 2) Apply bold styling to the header row (row 7)
      const headerRow = ["A7", "B7", "C7", "D7", "E7", "F7", "G7"];
      headerRow.forEach((cell) => {
        if (!worksheet[cell]) {
          worksheet[cell] = {}; // Make sure the cell is initialized if it doesn't exist
        }
        worksheet[cell].s = {
          font: { bold: true },
        };
      });

      // 3) Apply bold styling to the total row (row 4)
      const totalHeaders = ["A4", "B4", "C4", "D4"];
      totalHeaders.forEach((cell) => {
        if (!worksheet[cell]) {
          worksheet[cell] = {}; // Make sure the cell is initialized if it doesn't exist
        }
        worksheet[cell].s = {
          font: { bold: true },
        };
      });

      // 10) Création du workbook et écriture dans un fichier
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "trips.xlsx");
    });
  };

  /**
   * Sauvegarde un blob en fichier Excel via file-saver.
   * @param {ArrayBuffer} buffer - Le buffer binaire du fichier Excel.
   * @param {string} fileName - Nom du fichier (sans extension).
   */
  const saveAsExcelFile = (buffer) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const data = new Blob([buffer], { type: EXCEL_TYPE });

        // Si aucun nom n'est fourni, on prend "Courses" par défaut

        // Ajout d'un timestamp pour éviter de surcharger le même fichier
        const currentDate = new Date().toISOString().split("T")[0];
        const fileName = `Courses_${currentDate}`;

        // Sauvegarde via FileSaver
        module.default.saveAs(data, fileName);
      }
    });
  };

  const isLargeData = total > 5000;

  return (
    <div className="tooltip-container">
      <Tooltip
        target=".tooltip-container"
        content={
          isLargeData ? "Vous ne pouvez pas exporter plus de 5000 course" : ""
        }
        position="top"
      />
      <Button
        onClick={getExcelData}
        loading={isLoading}
        icon="pi pi-file-excel"
        label={<Translate>{EXPORT_EXCEL}</Translate>}
        severity="success"
        raised
        disabled={isLargeData}
      />
    </div>
  );
};

export default ExportExcel;
