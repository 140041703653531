import axios from "axios";
import Cookies from "js-cookie";

const apiKey = process.env.REACT_APP_URL_API;
// const apiKey = "https://devapi.taxiora.tn";

const axiosClient = axios.create({
  baseURL: apiKey,
});

axiosClient.interceptors.request.use(
  (config) => {
    const jwtToken = Cookies.get("_auth");

    if (jwtToken) {
      config.headers["X-AUTH-TOKEN"] = jwtToken;
    }

    config.headers["Content-Type"] = "text/plain";
    config.headers["Accept"] = "text/plain";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
