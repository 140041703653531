import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { isEmpty } from "./isEmpty";

import ServiceSettings from "./SettingBtns/ServiceSettings";

import { Button, Center, Space } from "@mantine/core";

import {
  ACTIVE,
  DATE_BEGIN,
  DATE_END,
  EDIT,
  SERVICE,
  UPDATE_SERVICE,
} from "Constant";

import HandleResult from "helper/HandleResult";
import UpdateBtn from "./UpdateBtn";

const ServicesComp = ({ data, taxiData }) => {
  const { t } = useTranslation();

  if (isEmpty(data)) return isEmpty(data);

  const taximeterCode = taxiData?.taximeter?.code_taximeter ?? null;
  const urlUpdate = `/operator/taximeter/${taximeterCode}/services`;

  const disabled = taxiData?.taximeter?.state === "free" ? false : true;

  return (
    <Fragment>
      {/* <UpdateBtn title={UPDATE_SERVICE} path={urlUpdate} disabled={disabled} /> */}
      <Space h="md" />

      <DataTable value={data} size="small">
        {/* Column mappings */}
        <Column field="label_service" header={t(SERVICE)} />
        <Column field="date_begin_service" header={t(DATE_BEGIN)} />
        <Column field="date_end_service" header={t(DATE_END)} />

        <Column
          header={t(ACTIVE)}
          body={(service) => <HandleResult data={service.active_service} />}
        />

        <Column
          header={t(EDIT)}
          body={(service) => <ServiceSettings service={service} />}
        />
      </DataTable>

      <Space h="md" />
    </Fragment>
  );
};

export default ServicesComp;
