import React, { useState } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { z } from "zod";
import phoneImage from "../../assets/images/logo/logo_final_taxiora.svg";
import { useLogin } from "../../hooks/useLogin";

// Définir le schéma de validation avec Zod
const loginSchema = z.object({
  username: z.string().nonempty("Le numéro de téléphone est requis"),
  password: z.string().nonempty("Le mot de passe est requis"),
});

const LoginPage = () => {
  const { login, isLoading } = useLogin();
  const [inputs, setInputs] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});

  const handleChange = (val, name) => {
    setInputs((prev) => ({
      ...prev,
      [name]: val,
    }));
    setErrors((prev) => ({ ...prev, [name]: null })); // Réinitialise l'erreur pour ce champ
  };

  const handleSignIn = () => {
    // Valider les données avec Zod
    const validation = loginSchema.safeParse(inputs);

    if (!validation.success) {
      // Si validation échoue, mettre à jour les erreurs
      const errorMessages = validation.error.errors.reduce((acc, err) => {
        acc[err.path[0]] = err.message;
        return acc;
      }, {});
      setErrors(errorMessages);
      return;
    }

    // Si validation réussit, appeler la fonction de connexion
    login(inputs);
  };

  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden p-input-filled"
  );

  return (
    <div className={containerClassName}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background:
              "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
          }}
        >
          <form
            className="w-full surface-card py-8 px-5 sm:px-8"
            style={{ borderRadius: "53px" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSignIn();
            }}
          >
            <div className="text-center mb-5">
              <img src={phoneImage} alt="Image" height="200" className="mb-3" />
              <div className="text-900 text-3xl font-medium mb-3">
                Bienvenue !
              </div>
              <span className="text-600 font-medium">
                Connectez-vous avec votre numéro de téléphone
              </span>
            </div>

            <div>
              <label
                htmlFor="username"
                className="block text-900 text-xl font-medium mb-2"
              >
                Numéro de téléphone
              </label>
              <InputText
                id="username"
                onChange={(e) => handleChange(e.target.value, "username")}
                type="text"
                placeholder="Entrez votre numéro de téléphone"
                className={`w-full mb-1 md:w-30rem ${
                  errors.username ? "p-invalid" : ""
                }`}
                style={{ padding: "1rem" }}
                autoComplete="off"
                // onKeyDown={handleKeyDown}
              />
              {errors.username && (
                <small className="p-error block">{errors.username}</small>
              )}

              <label
                htmlFor="password1"
                className="block text-900 font-medium text-xl mb-2"
              >
                Mot de passe
              </label>
              <Password
                feedback={false}
                inputId="password1"
                onChange={(e) => handleChange(e.target.value, "password")}
                placeholder="Votre mot de passe"
                toggleMask
                className={`w-full mb-1 ${errors.password ? "p-invalid" : ""}`}
                inputClassName="w-full p-3 md:w-30rem"
                autoComplete="off"
                // onKeyDown={handleKeyDown}
              ></Password>
              {errors.password && (
                <small className="p-error block">{errors.password}</small>
              )}

              <div className="flex align-items-center justify-content-between mb-5 gap-5">
                {/* <a
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  Mot de passe oublié ?
                </a> */}
              </div>
              <Button
                type="submit"
                label="Se connecter"
                className="w-full p-3 text-xl"
                onClick={() => handleSignIn()}
                loading={isLoading}
              ></Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
