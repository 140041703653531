import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "react-auth-kit";
import Routers from "./Routes";

import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

import AnimationThemeProvider from "./helperContext/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./helperContext/Customizer/CustomizerProvider";
import { useMaterialUIController } from "./helperContext/context";

import { ThemeProvider } from "@mui/material/styles";

import "@mantine/core/styles.css";
import "mantine-react-table/styles.css";

// import theme from "assets/theme";
import theme from "./assets/theme";
import themeDark from "./assets/theme-dark";

import "@mantine/core/styles/Input.css";

// import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-orange/theme.css";
import "leaflet/dist/leaflet.css";
import "./customform.css";

const App = () => {
  const queryClient = new QueryClient();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  document.documentElement.style.setProperty("--theme-default", "#ebca39");

  const value = {
    appendTo: "self",
  };

  return (
    <div className="App">
      <PrimeReactProvider value={value}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CustomizerProvider>
            <AnimationThemeProvider>
              <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                  <AuthProvider
                    authType={"cookie"}
                    authName={"_auth"}
                    cookieDomain={window.location.hostname}
                    cookieSecure={window.location.protocol === "https:"}
                  >
                    <Routers />
                  </AuthProvider>
                </QueryClientProvider>
              </Provider>
            </AnimationThemeProvider>
          </CustomizerProvider>
        </ThemeProvider>
      </PrimeReactProvider>
    </div>
  );
};

export default App;
