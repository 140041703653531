import { Fragment } from "react";
import { z } from "zod";

import { ACTIONS, ACTIVE, FULL_NAME, MAIL, PHONE } from "Constant";
import { civilityObj, createdAt, updatedAt } from "Constant/objs";

import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import { Group } from "@mantine/core";

export const API = {
  list: "/operator/drivers?index=-1",
  create: "/operator/user",
  get: "/operator/user/",
  edit: "/operator/user/",
};

// {
//   "code": "f03229df-141d-4d60-b674-c274f0194cdc",
//   "complete_name": "M Fathi Ben ",
//   "phone": "23234232",
//   "role": "AGENT_OPERATOR",
//   "mail": null,
//   "active": true,
//   "created_at": "2024-11-10 21:43:48",
//   "updated_at": "2024-11-10 22:58:10"
// },

// Columns
export const COLUMNS = [
  {
    name: ACTIONS,
    body: (data) => (
      <Group>
        <EditBtn
          read={`/operator/user/${data.code}`}
          extra={data.complete_name}
        />
        <ActiveBtn
          state={data.active}
          patch={`/operator/user/${data.code}/state`}
        />
      </Group>
    ),
  },
  {
    name: FULL_NAME,
    selector: "complete_name",
  },
  {
    name: "Role",
    selector: "role",
  },

  {
    name: PHONE,
    selector: "phone",
  },
  {
    name: MAIL,
    selector: "mail",
  },

  createdAt,
  updatedAt,

  // {
  //   body: (data) => {
  //     console.log(data);
  //     const image = data.image ?? driver;

  //     return <Avatar image={image} size="xlarge" shape="circle" />;
  //   },
  //   name: "img",
  // },
];

// Form
export const CREATE_TAXI_DRIVER = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
    schema: z.string().min(3, "Ce champ est obligatoire"),
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  civilityObj,
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
    schema: z.string().min(8, {
      message: "Le numéro de téléphone doit comporter au moins 8 chiffres.",
    }),
  },
];
