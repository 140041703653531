import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Text } from "@mantine/core";

const LanguageClass = () => {
  const languages = ["en", "fr", "du", "es", "pt", "tn"];
  const { i18n } = useTranslation();

  const currentLan = () => {
    const value = localStorage.getItem("i18nextLng");
    return languages.includes(value) ? value : "en";
  };

  const [selected, setSelected] = useState("fr");
  const [langdropdown, setLangdropdown] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelected(lng);
    setLangdropdown(false); // Close dropdown after selection
  };

  const flagClass = (lang) => {
    switch (lang) {
      case "en":
        return "flag-icon flag-icon-us";
      case "fr":
        return "flag-icon flag-icon-fr";
      case "du":
        return "flag-icon flag-icon-de";
      case "es":
        return "flag-icon flag-icon-es";
      case "pt":
        return "flag-icon flag-icon-pt";
      case "tn":
        return "flag-icon flag-icon-tn";
      default:
        return "flag-icon flag-icon-us";
    }
  };

  return (
    <Fragment>
      <div style={{ position: "relative", zIndex: 1000 }}>
        <Menu width={200} position="bottom-start" withArrow trigger="click">
          <Menu.Target>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: 8,
                marginLeft: 8,
              }}
            >
              <i
                className={flagClass(selected)}
                style={{ marginRight: 8, fontSize: "1rem" }}
              />
              <Text>{selected}</Text>
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            {languages.map((lang) => (
              <Menu.Item key={lang} onClick={() => changeLanguage(lang)}>
                <i className={flagClass(lang)} style={{ marginRight: 8 }} />
                {lang === "en" && "English (US)"}
                {lang === "fr" && "Français"}
                {lang === "du" && "Deutsch"}
                {lang === "es" && "Español"}
                {lang === "pt" && "Português (BR)"}
                {lang === "tn" && "العربية (tn)"}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </div>
    </Fragment>
  );
};

export default LanguageClass;
