import React from "react";
import { Card, CardBody } from "reactstrap";
import {
  CREATED_AT,
  DATE_BEGIN,
  DATE_END,
  DRIVER,
  MY_TRIPS,
  DURATION,
  PRICE,
  DISTANCE,
  TAXI,
  REFERANCE,
  DEPARTURE_ADDRESS,
  ARRIVAL_ADDRESS,
  STATUS,
  MY_BOOKINGS,
} from "Constant";

import Breadcrumbs from "Components/Breadcrumbs";

import BookingsDataTable from "./BookingsDataTable";
import moment from "moment";
import {
  formatTripDistance,
  formatTripDuration,
  getTripPrice,
} from "helper/trips";
import { Tag } from "primereact/tag";

// "status_label": "Accepté par taxi",
// "status_label": "Achevé",
// "status_label": "échec",

const getBookingStatus = (status) => {
  console.log(status);

  if (status === "Accepté par taxi")
    return (
      <Tag
        className="mr-2"
        icon="pi pi-car"
        severity="success"
        value={status}
      />
    );
  else if (status === "échec")
    return <Tag icon="pi pi-times" severity="danger" value={status} />;
  else return <Tag icon="pi pi-user" severity="success" value={status} />;
};
const BookingPage = () => {
  const breadCrumbsProps = [MY_BOOKINGS];
  const list = "/operator/bookings";

  const COLUMNS = [
    {
      name: REFERANCE,
      body: (data) => data.reference,
    },
    {
      name: DRIVER,
      body: (data) => data.driver,
      // driver_image
    },
    {
      name: TAXI,
      body: (data) => {
        // SEAT / Ibiza / 20210257 / 456TN227

        return `${data.licence} / ${data.car_number}`;
      },
    },
    {
      name: STATUS,
      body: (data) => getBookingStatus(data.status_label),
    },

    // {
    //   name: CREATED_AT,
    //   body: (data) => {
    //     return moment(data.created_at).format("YYYY-MM-DD HH:mm:ss");
    //   },
    // },

    {
      name: PRICE,
      body: (data) => getTripPrice(data.total_price),
    },
    {
      name: DURATION,
      body: (data) => formatTripDuration(data.time_spend),
    },
    {
      name: DISTANCE,
      body: (data) => formatTripDistance(data.traveled_distance),
    },
  ];

  return (
    <Card>
      <CardBody>
        <div className="p-4">
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <BookingsDataTable newColumns={COLUMNS} link={list} />
        </div>
      </CardBody>
    </Card>
  );
};

export default BookingPage;
