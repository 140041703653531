// ======= Sousse =======
// const EnvPosition = JSON.parse(process.env.REACT_APP_INIT_MAP_POSITION) ?? [
//   35.83422413, 10.59211575,
// ];
const EnvPosition = [36.80338623572442, 10.165601293368866];

export const getInitLocation = () => {
  try {
    const location = JSON.parse(localStorage.getItem("userData"))?.location;

    if (location) {
      const locationArray = location
        .replace(/[()]/g, "")
        .split(",")
        .map(Number)
        .reverse();

      return locationArray;
    }
  } catch {
    return EnvPosition;
  }

  return EnvPosition;
};

export const getLocation = (pos) => {
  return "";
};
