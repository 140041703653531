import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext"; // Import InputText for text input fields

import { DATE_BEGIN, DATE_END, DRIVER, SEARCH, TAXI, TOTAL } from "Constant";
import Translate from "Components/Translate";
import { Dropdown } from "primereact/dropdown";

const SeachAccordion = ({ columnFilters, setColumnFilters, total }) => {
  const calendarRef = useRef(null); // Ref for the calendar input
  const [inputs, setInputs] = useState(columnFilters);

  const handleChangeDate = (name, val) => {
    setInputs((prevState) => ({ ...prevState, [name]: val }));
  };

  useEffect(() => {
    setColumnFilters(inputs);
  }, [inputs, setColumnFilters]);

  const header = (
    <span
      className="flex align-items-center gap-2 w-full"
      style={{ color: "black" }}
    >
      <span className="font-bold white-space-nowrap">
        <Translate>{SEARCH}</Translate>
      </span>
      {/* <Badge
        value={
          <span>
            <strong>
              <Translate>{TOTAL}</Translate> :
            </strong>
            {total}
          </span>
        }
        className="ml-auto"
      /> */}
      <span className="ml-auto">
        <strong>
          <Translate>{TOTAL}</Translate> :
        </strong>
        {total}
      </span>
    </span>

    //   <span style={{ color: "black" }}>
    //     <Translate>{SEARCH}</Translate>
    //   </span>
  );

  const options = [
    {
      label: "SEAT / Ibiza / 20210257 / 456TN227",
      value: "38db8bcb-bf9b-4350-bb03-6c7754cecc1e",
    },
    {
      label: "Peugeot / 301 / 202103 / RS132116",
      value: "78398bfd-6ace-4333-b11f-3dc17b37f9d0",
    },
  ];

  return (
    <Accordion>
      <AccordionTab header={header}>
        {/* Grid responsive : 4 columns on desktop, 1 column on mobile */}
        <div className="grid p-fluid field col-12">
          <div className="field col-12 md:col-4">
            <label htmlFor="date_end">
              <Translate>{SEARCH}</Translate>
            </label>
            <div className="p-inputgroup" style={{ marginBottom: "1%" }}>
              <span className="p-inputgroup-addon">
                <i className="pi pi-search" style={{ marginRight: "8px" }} />
              </span>
              <InputText
                value={inputs.search || null}
                onChange={(e) => handleChangeDate("search", e.target.value)}
                placeholder="Search..."
                style={{ width: "100%" }}
              />
            </div>
          </div>
          {/* Date Begin Field */}
          <div className="field col-12 md:col-3">
            <label htmlFor="date_begin">
              <Translate>{DATE_BEGIN}</Translate>
            </label>
            <Calendar
              touchUI
              id="calendar-12h"
              value={inputs.date_begin || null}
              onChange={(e) => handleChangeDate("date_begin", e.value)}
              dateFormat="yy-mm-dd"
              showTime
              hourFormat="24"
              showIcon
              showClear
              showButtonBar
              ref={calendarRef} // Use the calendarRef here
              dropdownAppendTo={document.body}
            />
          </div>
          {/* Date End Field */}
          <div className="field col-12 md:col-3">
            <label htmlFor="date_end">
              <Translate>{DATE_END}</Translate>
            </label>
            <Calendar
              touchUI
              id="date_end"
              value={inputs.date_end || null}
              onChange={(e) => handleChangeDate("date_end", e.value)}
              placeholder="Select end date"
              dateFormat="yy-mm-dd"
              showTime
              hourFormat="24"
              showIcon
              showClear
              showButtonBar
              ref={calendarRef} // Use the calendarRef here as well
              dropdownAppendTo={document.body}
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="date_end">
              <Translate>{TAXI}</Translate>
            </label>
            <Dropdown
              value={inputs.taxi || null}
              onChange={(e) => handleChangeDate("taxi", e.value)}
              options={options}
              optionLabel="label"
              showClear
              placeholder="taxi"
              className="w-full md:w-14rem"
              size={5}
              appendTo={document.body} // This ensures the dropdown appears outside the table's scrollable area
            />
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default SeachAccordion;
