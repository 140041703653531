import HomePage from "Pages/HomePage";

import UsersPage from "Pages/UsersPage";

import TaxisPage from "Pages/TaxisPage";
import SheetOfTaxiPage from "Pages/SheetOfTaxiPage";
import BadgesPage from "Pages/BadgesPage";
import TripsPage from "Pages/TripsPage";
import BookingPage from "Pages/BookingPage";
import InvoicePage from "Pages/InvoicesPage";

const PAGE_ROUTES = [
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/users",
    element: <UsersPage />,
  },
  {
    path: "/taxis",
    element: <TaxisPage />,
  },
  {
    path: "/taxi/:code",
    element: <SheetOfTaxiPage />,
  },
  {
    path: "/badges",
    element: <BadgesPage />,
  },
  {
    path: "/trips",
    element: <TripsPage />,
  },
  {
    path: "/bookings",
    element: <BookingPage />,
  },
  {
    path: "/invoices",
    element: <InvoicePage />,
  },
];

export default PAGE_ROUTES;
