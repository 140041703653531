import { Paper, Text, Timeline } from "@mantine/core";
import { useTranslation } from "react-i18next";
import useFetchData from "hooks/useFetchDataWithDep";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumbs from "Components/Breadcrumbs";
import Loading from "Components/Loading/Loading";
import Translate from "Components/Translate";
import EmptyData from "Components/EmptyData";
import {
  CLIENT,
  DRIVERS,
  EQUIPEMENT,
  GENERAL_DATA,
  SCHEDULES,
  SERVICES,
  TAXI,
  TAXIMETER,
  TAXIS,
  TRIPS,
} from "Constant";
import {
  IconCar,
  IconCircuitVoltmeter,
  IconClockDollar,
  IconTool,
  IconUser,
  IconUsersGroup,
  IconWifi,
} from "@tabler/icons-react";
import TaxiComp from "./TaxiComp";
import TaximeterComp from "./TaximeterComp";
import ServicesComp from "./ServicesComp";
import EquipmentsComp from "./EquipmentsComp";
import UsersComp from "./UsersComp";
import { Accordion, AccordionTab } from "primereact/accordion";
import { isEmptyRes } from "./isEmpty";
import { TabPanel, TabView } from "primereact/tabview";
import { Card, CardBody } from "reactstrap";
import TripsComp from "./TripsComp";

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};

const SheetOfTaxiPage = () => {
  const { code } = useParams();
  const { t } = useTranslation();
  const { refresh } = useSelector((state) => state.searchLink);
  const url = `/operator/taxi/${code}/sheet`;
  const { data, isLoading, isError } = useFetchData(url, refresh);
  const taxiSheet = getRowsFromRes(data);

  if (isLoading) return <Loading />;
  if (isError) return <EmptyData />;

  const { taxi, users, services, equipments } = taxiSheet;

  const stepsList = [
    {
      accordion: false,
      icon: <IconCar size={30} />,
      title: GENERAL_DATA,
      content: <TaxiComp data={taxi} taxiData={taxiSheet} />,
    },
    {
      icon: <IconUsersGroup size={30} />,
      title: DRIVERS,
      content: <UsersComp data={users} taxiData={taxiSheet} />,
    },
    {
      empty: isEmptyRes(services),
      icon: <IconWifi size={30} />,
      title: SERVICES,
      content: <ServicesComp data={services} taxiData={taxiSheet} />,
    },
    {
      empty: isEmptyRes(equipments),
      icon: <IconTool size={30} />,
      title: EQUIPEMENT,
      content: <EquipmentsComp data={equipments} taxiData={taxiSheet} />,
    },
    {
      empty: isEmptyRes(equipments),
      icon: <IconTool size={30} />,
      title: TRIPS,
      content: <TripsComp code={code} />,
    },
  ];
  // SEAT / Ibiza / 20210257 / 456TN227
  const taxiLabel = `${taxi?.brand_taxi} / ${taxi?.model_taxi} / ${taxi?.registration_number} / ${taxi?.taxi_serial_number}`;
  const breadCrumbsProps = [
    { label: TAXIS, icon: "pi pi-car", url: "/taxis" },
    taxiLabel,
  ];

  //   taxi_serial_number
  //
  //
  //

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <TabView>
            {stepsList.map((elem, i) => {
              const { title, icon, list } = elem;
              const header = (
                <span className="flex align-items-center gap-2">
                  <i className={icon}></i>
                  <Translate>{title}</Translate>
                </span>
              );
              return (
                <TabPanel key={i} header={header}>
                  <div className="p-4">{elem.content}</div>
                </TabPanel>
              );
            })}
          </TabView>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default SheetOfTaxiPage;
