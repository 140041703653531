import { Group } from "@mantine/core";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import {
  ACTIONS,
  BRAND,
  CIRCULATION_DATE,
  CLIENT,
  DRIVERS,
  LICENCE,
  LICENCE_EXPIRE_AT,
  LICENCE_GOT_AT,
  MODEL,
  PLACES,
  READY,
  REGISTRATION_NUMBER,
  SIMS,
  TAXI,
  TAXIMETER,
} from "Constant";

export const API = {
  list: "/operator/taxis?index=-1",
};

export const COLUMNS = [
  {
    name: TAXI,
    selector: "taxi",
  },
  {
    name: TAXIMETER,
    selector: "taximeter",
  },
  {
    name: PLACES,
    selector: "places",
  },

  {
    name: CIRCULATION_DATE,
    selector: "circulation_date",
  },
  {
    name: LICENCE_GOT_AT,
    selector: "licence_got_at",
  },
  {
    name: LICENCE_EXPIRE_AT,
    selector: "licence_expire_at",
  },

  {
    name: DRIVERS,
    selector: "count_drivers",
  },

  {
    id: "actions",
    button: "true",
    size: 5,
    Cell: ({ row }) => {
      const { code } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/taxi/${code}`} />
        </Group>
      );
    },
  },
];

export const taxis = [
  {
    code: "38db8bcb-bf9b-4350-bb03-6c7754cecc1e",
    taxi: "SEAT / Ibiza / 20210257 / 456TN227",
    taximeter: "VT5LJN510040019",
    name_client: "taxi sousse Tes/M Haroun Bouriga",
    code_client: "47fff794-601f-402a-8176-89681bd9009d",
    state: "off",
    places: 5,
    circulation_date: "23-11-2009",
    licence_got_at: "31-12-2010",
    licence_expire_at: "31-12-2030",
    count_drivers: 2,
    active: true,
  },
  {
    code: "0236ef48-04c5-475b-a922-127398933e66",
    taxi: "Peugeot / 301 / ttt / 44TN539",
    taximeter: null,
    name_client: "taxi sousse Tes/M Haroun Bouriga",
    code_client: "47fff794-601f-402a-8176-89681bd9009d",
    state: null,
    places: 5,
    circulation_date: "14-10-2024",
    licence_got_at: "07-10-2024",
    licence_expire_at: "21-10-2024",
    count_drivers: 0,
    active: true,
  },
  {
    code: "6502487d-824e-4a6d-835e-c1e4a430a399",
    taxi: "Peugeot / 301 / FDFDs / F4R",
    taximeter: null,
    name_client: "taxi sousse Tes/M Haroun Bouriga",
    code_client: "47fff794-601f-402a-8176-89681bd9009d",
    state: null,
    places: 4,
    circulation_date: "02-09-2024",
    licence_got_at: "01-05-2024",
    licence_expire_at: "01-01-2025",
    count_drivers: 0,
    active: true,
  },
  {
    code: "eabf12d8-4cd9-490d-b752-56801b9d3b7a",
    taxi: "Peugeot / 301 / FSD / DSQDq",
    taximeter: null,
    name_client: "taxi sousse Tes/M Haroun Bouriga",
    code_client: "47fff794-601f-402a-8176-89681bd9009d",
    state: null,
    places: 5,
    circulation_date: "10-09-2024",
    licence_got_at: "01-01-2010",
    licence_expire_at: "01-02-2025",
    count_drivers: 0,
    active: true,
  },
  {
    code: "f956f95a-0c53-45bb-b59f-5eab5053bd1a",
    taxi: "Peugeot / 301 / 432 / DAEF",
    taximeter: null,
    name_client: "taxi sousse Tes/M Haroun Bouriga",
    code_client: "47fff794-601f-402a-8176-89681bd9009d",
    state: null,
    places: 5,
    circulation_date: "02-09-2024",
    licence_got_at: "01-01-2010",
    licence_expire_at: "31-12-2024",
    count_drivers: 0,
    active: true,
  },
  {
    code: "78398bfd-6ace-4333-b11f-3dc17b37f9d0",
    taxi: "Peugeot / 301 / 202103 / RS132116",
    taximeter: "VT5LJN51001005",
    name_client: "taxi sousse Tes/M Haroun Bouriga",
    code_client: "47fff794-601f-402a-8176-89681bd9009d",
    state: "free",
    places: 5,
    circulation_date: "30-01-2024",
    licence_got_at: "06-12-2023",
    licence_expire_at: "26-04-2024",
    count_drivers: 2,
    active: true,
  },
  {
    code: "e559766c-b4c1-4129-a7eb-5a4fbf44487d",
    taxi: "Peugeot / 301 / 202100 / RS132115",
    taximeter: null,
    name_client: "taxi sousse Tes/M Haroun Bouriga",
    code_client: "47fff794-601f-402a-8176-89681bd9009d",
    state: null,
    places: 5,
    circulation_date: "31-12-2019",
    licence_got_at: "31-12-2010",
    licence_expire_at: "31-12-2030",
    count_drivers: 2,
    active: true,
  },
];
