import { Space } from "@mantine/core";
import React, { Fragment } from "react";

import { EQUIPEMENT, VALUES } from "Constant";

import Translate from "Components/Translate";

import { isEmpty } from "./isEmpty";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const EquipmentsComp = ({ data, taxiData }) => {
  if (isEmpty(data)) return isEmpty(data);

  const {
    box,
    luminous,
    printer,
    sunshield,
    sos_button,
    stylus,
    relay_switch,
    sensor,
    obd_adapter,
    bracket,
  } = data;
  console.log("luminous:", luminous?.luminous);
  console.log("luminousluminousluminousluminous:", luminous?.luminous);

  const requiredKeys = [
    { title: "Box", serial: box?.box },
    { title: "Luminous", serial: luminous?.luminous },
    { title: "Printer", serial: printer?.printer },
    { title: "Sunshield", serial: sunshield?.sunshield },
    { title: "SOS Button", serial: sos_button?.sos_button },
    { title: "Stylus", serial: stylus?.stylus },
    { title: "Relay Switch", serial: relay_switch?.relay_switch },
    { title: "Sensor", serial: sensor?.sensor },
    { title: "OBD Adapter", serial: obd_adapter?.obd_adapter },
    { title: "Bracket", serial: bracket?.bracket },
  ];

  const taximeterCode = taxiData?.taximeter?.code_taximeter ?? null;

  return (
    <Fragment>
      <Space h="md" />

      <DataTable size="small" value={requiredKeys} responsiveLayout="scroll">
        <Column field="title" header={<Translate>{EQUIPEMENT}</Translate>} />
        <Column field="serial" header={<Translate>{VALUES}</Translate>} />
      </DataTable>
      {/* <DataTable value={requiredKeys} size="small">
        <Column field="title" header="Equipment" />
        <Column
          field="serial"
          header="Serial"
          body={({ serial }) => {
            if (!serial) return <IconXboxX color="red" />;
          }}
        />
      </DataTable> */}
    </Fragment>
  );
};

export default EquipmentsComp;
