import React from "react";
import { RequireAuth } from "react-auth-kit";
import {
  Route,
  Routes as Switch,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import PAGE_ROUTES from "./pages.routes";
import NotFoundPage from "Pages/NotFoundPage";
import LoginPage from "Pages/LoginPage";
import HomePage from "Pages/HomePage";
import Layout from "Layout/Layout";

export default function Routes() {
  return (
    <Router>
      <Switch>
        {PAGE_ROUTES.map((onePage, index) => (
          <Route
            key={index}
            path={onePage.path}
            // element={
            //   <RequireAuth loginPath="/login">
            //     <Layout>{onePage.element}</Layout>
            //   </RequireAuth>
            // }
            element={<Layout>{onePage.element}</Layout>}
          />
        ))}

        <Route key="NotFound" path="/home" element={<NotFoundPage />} />

        <Route key="LoginPage" path="/login" element={<LoginPage />} />
        <Route
          key="LoginPage"
          path="/home"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />

        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Switch>
    </Router>
  );
}
