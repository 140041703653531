import { CREATED_AT, UPDATED_AT } from "Constant";
import { formatDate } from "helper/dateFormat";

export const activeObj = {
  name: "active",
  title: "Active",
  inputForm: "SelectInput",
  options: [
    {
      value: "",
      label: "all",
      name: "active",
    },
    {
      value: "true",
      label: "active",
      name: "active",
    },
    {
      value: "false",
      label: "not active",
      name: "active",
    },
  ],
};

export const civilityOptions = [
  {
    value: "M",
    label: "Mr",
    name: "civility",
  },
  {
    value: "MME",
    label: "Mrs",
    name: "civility",
  },
];

export const civilityObj = {
  name: "civility",
  title: "Civility",
  inputForm: "SelectInput",
  options: civilityOptions,
};

export const captureObj = {
  title: "Capture capture",
  name: "clientCapture",
  inputForm: "SelectInput",
  options: [
    {
      value: "FB",
      label: "Facebook",
      name: "capture",
    },
    {
      value: "Instagram",
      label: "Instagram",
      name: "capture",
    },
    {
      value: "Tiktok",
      label: "Tiktok",
      name: "capture",
    },
    {
      value: "Email",
      label: "Email",
      name: "capture",
    },
    {
      value: "Events",
      label: "Events",
      name: "capture",
    },
    {
      value: "Mouth to ear",
      label: "Mouth to ear",
      name: "capture",
    },
    {
      value: "Others",
      label: "Others",
      name: "capture",
    },
  ],
};

export const saleOrTry = {
  name: "type",
  title: "Type",
  inputForm: "SelectInput",
  options: [
    {
      value: "sale",
      label: "Sale",
    },
    {
      value: "try",
      label: "Try",
    },
  ],
};

export const createdAt = {
  name: CREATED_AT,
  selector: "created_at",
};

export const updatedAt = {
  name: UPDATED_AT,
  selector: "updated_at",
};
