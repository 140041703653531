import React from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

import { CREATE_TAXI, CREATE_USER, REFRESH } from "Constant";

import { useDispatch } from "react-redux";
import { toggleRefresh } from "../../redux/searchLink";

const LeftToolBar = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const onRefresh = () => {
    dispatch(toggleRefresh());
  };
  return (
    <div className="flex flex-wrap gap-2">
      <Button
        label={t(REFRESH)}
        icon="pi pi-sync"
        severity="secondary"
        raised
        onClick={() => onRefresh()}
      />
    </div>
  );
};

export default LeftToolBar;
