import React, { useState, useEffect, Fragment } from "react";
import { DataTable as PrimeDataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import useFetchData from "hooks/useFetchDataWithDep";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import { useTranslation } from "react-i18next";
import { THERE_IS_NO_DATA_FOUND, TOTAL } from "Constant";
import Translate from "Components/Translate";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";

// Main SimpleDataTable Component
export default function SimpleDataTable({
  newColumns,
  link,
  localsearch = true,
  localData,
}) {
  const [search, setSearch] = useState(""); // State for search input
  const { refresh } = useSelector((state) => state.searchLink);

  const { t } = useTranslation(); // i18n translation hook
  const { data, isLoading, isError } = useFetchData(link, link, refresh); // Fetch data using custom hook

  // If loading, show the loading component
  if (isLoading) return <Loading />;
  // Uncomment to show NotFound component if data fetching fails
  // if (isError) return <NotFound />;

  // Extracting rows and total from the fetched data
  console.log("getRows(localData), : ", getRows(localData));

  const { total: paginationTotalRows, rows: result } = localData
    ? getRows(localData)
    : getRows(data);
  const listOfData = filterBySearch(result, search);

  return (
    <Fragment>
      {localsearch && (
        <div style={{ marginBottom: "1%" }}>
          <SearchBar
            search={search}
            setSearch={setSearch}
            total={listOfData?.length}
          />
        </div>
      )}

      <PrimeDataTable
        value={listOfData} // Data for the table
        showGridlines
        stripedRows
        tableStyle={{ minWidth: "30rem" }} // Adjust table width
        className="custom-datatable" // Apply custom CSS class
        size="small" // Small row size
        emptyMessage={t(THERE_IS_NO_DATA_FOUND)} // Empty state message
      >
        {newColumns.map((col, i) => (
          <Column
            key={i}
            body={col.body ? col.body : null}
            field={col.selector ? col.selector : null}
            header={t(col.name)}
          />
        ))}
      </PrimeDataTable>
    </Fragment>
  );
}

// Helper function to get rows and total from the fetched data
const getRows = (data) => {
  try {
    return data.data.results.data; // Return rows if data is available
  } catch {
    return { total: 1, rows: [] }; // Return empty rows if an error occurs
  }
};

// SearchBar Component
const SearchBar = ({ search, setSearch, total }) => {
  return (
    <div className="p-inputgroup">
      <span className="p-inputgroup-addon">
        <i className="pi pi-search" style={{ marginRight: "8px" }} />
        <Translate>{TOTAL}</Translate>
        <span style={{ marginLeft: "8px" }}>{total}</span>
      </span>
      <InputText
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
        style={{ width: "100%" }}
      />
    </div>
  );
};

const filterBySearch = (array, searchString) => {
  if (!searchString) return array; // If no search string, return the full array

  const lowerCasedSearchString = searchString.toLowerCase();

  return array.filter((item) => {
    // Iterate through all keys of the item
    return Object.values(item).some((value) => {
      // Convert the value to string (in case it's a number or boolean)
      const valueString = String(value).toLowerCase();
      return valueString.includes(lowerCasedSearchString);
    });
  });
};
