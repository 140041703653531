import Swal from "sweetalert2";

const YOU_WONT_BE_ABLE_TO_REVERT_THIS =
  "Vous ne pouvez pas versé sur votre choix !";
const YOUR_DATA_HAS_BEEN_CHANGED = "Vos données ont été modifiées";
const YES_CHANGE_IT = "Oui, changez-la !";
const ARE_YOU_SURE = "Voulez-vous confirmer ?";
const CANCEL = "Annuler";

export const addAlert = () => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: YOUR_DATA_HAS_BEEN_CHANGED,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const areYouSureAlert = () => {
  return Swal.fire({
    title: ARE_YOU_SURE,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: YES_CHANGE_IT,
    cancelButtonText: CANCEL,
  });
};

export const areYouSureToDeleteAlert = () => {
  return Swal.fire({
    title: ARE_YOU_SURE,
    text: YOU_WONT_BE_ABLE_TO_REVERT_THIS,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: YES_CHANGE_IT,
  });
};

export const changedAlert = () => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: YOUR_DATA_HAS_BEEN_CHANGED,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const ShowErrorMsg = (value) => {
  return (
    <div style={{ zIndex: 99 }}>
      {Swal.fire({
        text: "Une erreur technique s'est produite.",
        icon: "error",
      })}
    </div>
  );
};

export const NotUnique = (parameter, value) => {
  return Swal.fire({
    title: value,
    text: parameter,
    icon: "warning",
  });
};

export const MissingParameters = (parameter, value) => {
  const missingParams = Object.keys(parameter);

  return Swal.fire({
    title: value + " " + parameter[missingParams[0]],
    text: missingParams,
    icon: "warning",
  });
};
