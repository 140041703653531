// Fichier: TaxiComp.js
import React, { useState } from "react";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";

// Exemple d'image ou d'icône pour l'avatar taxi
import taxiImg from "../../assets/images/taxi.svg";
import { getIconEmpty } from "helper/getData";

// Vérifiez que vous importez primeicons/primeicons.css
// pour pouvoir utiliser pi pi-eye et pi pi-eye-slash

const TaxiComp = ({ data }) => {
  // État local pour basculer l’affichage (true = on affiche les détails)
  const [open, setOpen] = useState(true);

  // Vérifier si data est vide (au besoin, remplacez par votre propre check)
  if (!data || Object.keys(data).length === 0) {
    return <div>Aucune donnée de taxi à afficher</div>;
  }

  // Déstructurer les propriétés de l’objet taxi
  const {
    code_taxi,
    licence,
    licence_got_at,
    licence_expire_at,
    circulation_date,
    taxi_serial_number,
    registration_number,
    model_taxi,
    brand_taxi,
    active_taxi,
    places,
    cg_doc,
    licence_doc,
  } = data;

  // Déterminer la couleur/texte du Tag (actif/inactif)
  const indicatorProps = active_taxi
    ? { severity: "success", value: "Active" }
    : { severity: "danger", value: "Not active" };

  // Label principal (ex. "Peugeot / 301 / RS132116")
  const taxiLabel = `${brand_taxi} / ${model_taxi} / ${registration_number}`;

  // Liste des champs "classiques" à afficher dans les détails
  const requiredFields = [
    { title: "Licence obtenue le", value: licence_got_at },
    { title: "Licence expire le", value: licence_expire_at },
    { title: "Date de circulation", value: circulation_date },
    { title: "Numéro de série", value: taxi_serial_number },
    { title: "Nombre de places", value: places },
  ];

  // Icône pour l’affichage ou la fermeture (œil / œil barré)
  const iconClass = open ? "pi pi-eye-slash" : "pi pi-eye";

  // "title" de la Card : Avatar, Tag de statut, Label du taxi, et icône clickable
  const title = (
    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
      {/* Avatar taxi (image) */}
      <Avatar image={taxiImg} />

      {/* Tag (Active / Not active) */}
      <Tag {...indicatorProps} />

      {/* Label principal du taxi */}
      <span style={{ fontWeight: 600, fontSize: "1rem" }}>{taxiLabel}</span>

      {/* Icône pour afficher/masquer les détails, poussé à droite */}
      <i
        className={iconClass}
        style={{
          marginLeft: "auto",
          cursor: "pointer",
          fontSize: "1.3rem",
          color: "#888",
        }}
        onClick={() => setOpen(!open)}
      />
    </div>
  );

  return (
    <Card
      title={title}
      style={{
        width: "100%",
        marginBottom: "16px",
        border: "1px solid #ccc",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
      }}
    >
      {/* Contenu des détails affichés si open === true */}
      {open && (
        <div style={{ marginTop: "16px" }}>
          {/* Section "Champs de base" */}
          {requiredFields.map((item, index) => (
            <div
              key={index}
              style={{
                marginBottom: "8px",
                display: "flex",
              }}
            >
              <strong style={{ marginRight: "6px" }}>{item.title} :</strong>
              <span>{item.value}</span>
            </div>
          ))}

          {/* Section "Documents" */}
          <div style={{ marginTop: "16px" }}>
            <h4 style={{ marginBottom: "8px" }}>Documents :</h4>

            <div style={{ marginBottom: "8px", display: "flex" }}>
              <strong style={{ marginRight: "6px" }}>Carte grise :</strong>

              {cg_doc && (
                <a href={cg_doc} target="_blank" rel="noopener noreferrer">
                  Ouvrir la carte grise
                </a>
              )}
              {!cg_doc && getIconEmpty(cg_doc)}
            </div>
            {/* Lien vers la licence_doc */}
            <div style={{ marginBottom: "8px", display: "flex" }}>
              <strong style={{ marginRight: "6px" }}>Licence :</strong>

              {licence_doc && (
                <a href={licence_doc} target="_blank" rel="noopener noreferrer">
                  Ouvrir la licence
                </a>
              )}
              {!licence_doc && getIconEmpty(licence_doc)}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default TaxiComp;
