import { ShowErrorMsg, addAlert } from "../Components/Alerts/Alerts";
import axiosClient from "./axiosClient";
import { toggleRefresh } from "../redux/searchLink";
import { store } from "../redux/store";

const patchData = async (newData, URL_CREATE, changeLoadingState, success) => {
  await axiosClient
    .patch(URL_CREATE, newData)
    .then(() => {
      addAlert();
      if (success) success();
    })
    .catch((error) => {
      ShowErrorMsg(error);
    });
  if (changeLoadingState) changeLoadingState(false);
};

export default patchData;
