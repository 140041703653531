import { Dropdown } from "primereact/dropdown";
import React from "react";
import patchItem from "Services/patchItem";

const BadgeToDriver = ({ row, drivers }) => {
  const options = drivers
    ? drivers?.rows?.map((driver) => ({
        value: driver?.code,
        label: driver?.completename,
      }))
    : [];

  const badgeCode = row.code;
  const currenctDriver = row.driver ?? "";

  const handleChage = (event) => {
    const newDriver = event.value;

    const unavailableBadge = `/back/user/${currenctDriver}/badge`;

    if (event.value) {
      const url = `/back/user/${newDriver}/badge/${badgeCode}`;
      patchItem(url);
    } else patchItem(unavailableBadge);
  };

  return (
    <Dropdown
      value={currenctDriver}
      // placeholder={serialNumber}
      onChange={handleChage}
      options={options}
      optionLabel="label"
      showClear
      appendTo={document.body}
      filter
    />
  );
};

export default BadgeToDriver;
// https://devapi.taxiora.tn/back/user//badge/

// https://devapi.taxiora.tn/back/user/5b1c1d1f-6934-4f0b-a407-9d6929586dd8/badge/BG0000000005

// https://devapi.taxiora.tn/back/user/657c5dfe-0255-41e2-89a3-10b528475771/badge/e72eabf9-8d99-4cd4-8eb2-ac618af3180b
