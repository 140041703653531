import Translate from "Components/Translate";
import { CREATE, REFRESH } from "Constant";
import { Button } from "primereact/button";
import React from "react";
import { toggleRefresh } from "../../redux/searchLink";
import { store } from "../../redux/store";
import { useDispatch } from "react-redux";
import { changeModalType } from "../../redux/modal";

export const RefreshBtn = () => {
  return (
    <Button
      onClick={() => store.dispatch(toggleRefresh())}
      // icon="pi pi-refresh"
      // severity="secondary"
      // outlined
      label={<Translate>{REFRESH}</Translate>}
      icon="pi pi-sync"
      severity="secondary"
      // raised
    />
  );
};

export const CraeteBtn = ({ modalType }) => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };

  return (
    <Button
      onClick={() => changeState(modalType ?? "create")}
      label={<Translate>{CREATE}</Translate>}
      icon="pi pi-plus"
      severity="success"
      raised
    />
  );
};
