import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import Swal from "sweetalert2";

import { login as loginApi } from "../Services/apiAuth";
import useLocalStorage from "./useLocalStorage";

export function useLogin() {
  const { setData } = useLocalStorage();

  const signIn = useSignIn();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate: login, isLoading } = useMutation({
    mutationFn: ({ username, password }) => loginApi({ username, password }),
    onSuccess: (user) => {
      signIn({
        token: user.data.results.jwt,
        expiresIn: user.data.results.expire_in,
        tokenType: "Bearer",
        authState: { email: user.data.results.data.mail },
      });

      localStorage.setItem("userData", user);

      setData("userData", user?.data?.results?.data);
      setData("currency", user.data.results?.currency);
      queryClient.setQueryData(["user"], user);

      navigate("/home", { replace: true });

      // const { role } = user.data.results.data;

      // switch (role) {
      //   case "AGENT_PRODUCTION":
      //     navigate("/work-order", { replace: true });
      //     break;
      //   case "AGENT_PCB_CONTROL":
      //     navigate("/work-order", { replace: true });
      //     break;
      //   case "AGENT_SOFTWARE_CONTROL":
      //     navigate("/work-order", { replace: true });
      //     break;
      //   case "AGENT_PACKAGING":
      //     navigate("/work-order", { replace: true });
      //     break;
      //   case "ADMIN_PRODUCTION":
      //     navigate("/work-order", { replace: true });
      //     break;
      //   case "AGENT_STOCK":
      //     navigate("/raw-material-stocks", { replace: true });
      //     break;
      //   case "ADMIN_STOCK":
      //     navigate("/raw-material-stocks", { replace: true });
      //     break;
      //   case "ADMIN_COMR":
      //     navigate("/client", { replace: true });
      //     break;
      //   case "SUPER_ADMIN":
      //     navigate("/client", { replace: true });

      //     break;
      //   default:
      //     break;
      // }
    },
    onError: (err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Numéro de téléphone ou mot de passe incorrect.",
      });
      //   toast.error('Provided email or password are incorrect');
    },
  });
  return { login, isLoading };
}
