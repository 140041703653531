import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";

import driver from "../../assets/images/taxi.svg";

import OpenModalBtn from "Components/ModalApp/OpenModalBtn";
import Translate from "Components/Translate";

import patchItem from "Services/patchItem";

const toggleDriverStatus = (code) => {
  const patchApi = `/operator/driver/${code}`;
  patchItem(patchApi);
};

const TaxiStatus = ({ state }) => {
  console.log("state", state);

  const stateTanslate = {
    free: "Libre",
    off: "hors service",
    busy: "Occupé",
  };
  const stateColors = {
    free: "green",
    off: "gray",
    busy: "red",
  };

  console.log(stateTanslate[state]);

  return (
    <Tag
      value={stateTanslate[state] ?? "hors service"}
      style={{
        backgroundColor: stateColors[state] ?? "gray",
        color: "white",
        borderRadius: "4px",
        padding: "0.5rem 1rem",
      }}
    />
  );
};

export const gridItem = (data) => {
  console.log(data);

  const { code, taxi, taximeter, image, active, state } = data;

  const selectedRow = {
    getLink: `/operator/driver/${code}`,
    code,
  };

  const titleModal = taxi;

  return (
    <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={code}>
      <div className="p-4 border-1 surface-border surface-card border-round">
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
          <div className="flex align-items-center gap-2">
            <i className="pi pi-tablet"></i>
            <span className="font-semibold">{taximeter ?? "--"}</span>
          </div>
          <TaxiStatus state={state} />
        </div>
        <Divider />
        <div className="flex flex-column align-items-center gap-1">
          <OpenModalBtn
            type="editUserImg"
            title={titleModal}
            data={selectedRow}
          >
            {image ? (
              <Avatar
                image={image}
                size="large"
                shape="circle"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <img src={driver} alt={code} style={{ cursor: "pointer" }} />
            )}
          </OpenModalBtn>
          <div className="text-2xl font-bold">{taxi ?? "--"}</div>
          {/* <div className="text-1xl font-bold">{taximeter}</div> */}
        </div>
        <Divider />
        <div className="flex align-items-center justify-content-between">
          <InputSwitch
            inputId={`driver-${code}`}
            checked={active}
            onChange={() => toggleDriverStatus(code)}
          />
          <div style={{ display: "flex", gap: "2%" }}>
            <Button
              icon="pi pi-eye"
              severity="success"
              className="p-button-rounded"
              onClick={() => {
                window.open(`/taxi/${code}`, "_blank");
              }}
            />
            {/* <OpenModalBtn title={titleModal} type="editUser" data={selectedRow}> */}
            <Button icon="pi pi-pencil" className="p-button-rounded" disabled />
            {/* </OpenModalBtn> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const listItem = (data, index) => {
  const { code, taxi, taximeter, image, active, state } = data;

  const selectedRow = {
    getLink: `/operator/driver/${code}`,
    code,
  };

  const titleModal = taxi;

  return (
    <div className="col-12" key={code}>
      <div
        className={classNames(
          "flex flex-column xl:flex-row p-4 gap-4 surface-card",
          { "border-top-1 surface-border": index !== 0 }
        )}
      >
        <div className="flex align-items-center gap-3">
          <OpenModalBtn
            type="editUserImg"
            title={titleModal}
            data={selectedRow}
          >
            {image ? (
              <Avatar
                image={image}
                size="large"
                shape="circle"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <img src={driver} alt={code} style={{ cursor: "pointer" }} />
            )}
          </OpenModalBtn>
          <div className="text-xl font-bold">
            {taxi}
            <div className="flex align-items-center gap-2">
              <i className="pi pi-tablet"></i>
              <span className="font-semibold">{taximeter ?? "--"}</span>
              <div className="flex align-items-center gap-2">
                <span className="font-semibold">{taximeter ?? "--"}</span>
              </div>
              <div className="flex flex-column justify-content-center gap-3">
                <TaxiStatus state={state} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-column xl:align-items-end gap-3 ml-auto">
          <InputSwitch
            inputId={`driver-${code}`}
            checked={active}
            onChange={() => toggleDriverStatus(code)}
          />
          <Button
            icon="pi pi-eye"
            severity="success"
            className="p-button-rounded"
            onClick={() => {
              window.open(`/taxi/${code}`, "_blank");
            }}
          />

          {/* <OpenModalBtn type="editUser" data={selectedRow} title={titleModal}> */}
          <Button icon="pi pi-pencil" className="p-button-rounded" disabled />
          {/* </OpenModalBtn> */}
        </div>
      </div>
    </div>
  );
};
