import React from "react";
import { Card, CardBody } from "reactstrap";
import {
  CREATED_AT,
  DRIVER,
  MODEL,
  MY_BADGES,
  SERIAL_NUMBER,
  STATUS,
  UPDATED_AT,
} from "Constant";

import Breadcrumbs from "Components/Breadcrumbs";

import BadgesDataTable from "./BadgesDataTable";
import useFetchData from "hooks/useFetchData";
import BadgeToDriver from "./BadgeToDriver";
import { Tag } from "primereact/tag";
import Loading from "Components/Loading/Loading";
import { getData } from "helper/getData";

const statusColor = {
  activated: "green",
  in_stock: "orange",
};

const BadgesPage = () => {
  const breadCrumbsProps = [MY_BADGES];
  const list = "/operator/badges?index=-1";
  const { data, isLoading, isError } = useFetchData("/operator/drivers");
  const drivers = getData(data);

  if (isLoading) return <Loading />;

  const COLUMNS = [
    {
      name: MODEL,
      selector: "model",
    },
    {
      name: SERIAL_NUMBER,
      selector: "serial_number",
    },
    {
      name: STATUS,
      body: (data) => (
        <Tag
          value={data.status_label}
          style={{
            backgroundColor: statusColor[data.status] ?? "gray",
            color: "white",
            borderRadius: "4px",
            padding: "0.5rem 1rem",
          }}
        />
      ),
    },

    {
      name: DRIVER,
      body: (data) => {
        return <BadgeToDriver row={!isError ? data : []} drivers={drivers} />;
      },
    },
    {
      name: CREATED_AT,
      selector: "created_at",
    },
    {
      name: UPDATED_AT,
      selector: "updated_at",
    },
  ];

  return (
    <Card>
      <CardBody>
        <div className="p-4">
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <BadgesDataTable createBtn={false} newColumns={COLUMNS} link={list} />
        </div>
      </CardBody>
    </Card>
  );
};

export default BadgesPage;
