import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import { HOME, MY_TAXIS } from "Constant";

import Main from "./Main";

import { getCodeFromJWT } from "helper/getDataFromCookies";
import { Helmet } from "react-helmet";
import { BreadCrumb } from "primereact/breadcrumb";
import Translate from "Components/Translate";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const breadCrumbsProps = [];
  const { t } = useTranslation();

  const clientCode = getCodeFromJWT();
  const title = t(HOME);
  const home = { label: title, icon: "pi pi-home" };

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Helmet>
            <title>{title}</title>
          </Helmet>

          <BreadCrumb
            model={[]}
            home={home}
            style={{
              border: "none",
              background: "none",
              boxShadow: "none",
              marginLeft: "-3rem", // ou -2rem selon l'effet désiré
            }}
          />
          <Main operatorCode={clientCode} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default HomePage;
